<template>
  <div v-if="isDataReady" class="recognition-setting">
    <div class="container-canvas">
      <VideoRoiLpr />
    </div>
    <div class="container-setting">
      <LprSetting v-if="aiRunOn === 'device' && (isLprCam || isAiCam)" />
      <LprSettingAibox v-else />
    </div>
    <Tags 
      class="container-tags" 
      :title="$t('ai_lpr_tag')/*車輛標記*/"
      :isEdit="isEdit" 
      :disabled="isEditRoi"
    />
    
    <Subscribers 
      class="container-receiver" 
      :isEdit="isEdit" 
      :disabled="isEditRoi"
      :subscribers="useSubscribers" 
      @change="onChangeSubscribers"
    />
    <Note 
      class="container-note" 
      v-model="useNote" 
      :isEdit="isEdit" 
      :disabled="isEditRoi" 
    />
    <div class="container-editor" :class="{ end: isEdit }">
      <Editor v-if="!isEdit" :userId="editorUserId" />
      <div v-else class="actions" :class="{ disabled: isEditRoi }">
        <div class="btn cancel" @click="cancelTaskEdit">{{ $t('cancel')/*取消*/ }}</div>
        <div class="btn confirm" :class="{ disabled: !isModified }" @click="handleTaskEdit">{{ $t('confirm')/*確認*/ }}</div>
      </div>
    </div>
  </div>
  <div v-else class="recognition-setting">
    <Loading :active.sync="isLoading"
      color="#FFC600"
      background="transparent"
      :width="80"
      :height="80"
      :opacity="0.1"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage">
    </Loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'RecognitionLpr',
  components: {
    Loading,
    VideoRoiLpr: () => import('@/components/AiBox/base/VideoRoiLpr.vue'),
    LprSetting: () => import('@/components/AiBox/base/LprSetting.vue'),
    LprSettingAibox: () => import('@/components/AiBox/base/LprSettingAibox.vue'),
    Tags: () => import('@/components/AiBox/base/Tags.vue'),
    Subscribers: () => import('@/components/AiBox/base/Subscribers.vue'),
    Note: () => import('@/components/AiBox/base/Note.vue'),
    Editor: () => import('@/components/AiBox/base/Editor.vue'),
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
    }
  },
  computed: {
    ...mapState('account', ['groupList']),
    ...mapState('aibox', [
      'aiRunOn',
      'aiMode',
      'isEdit',
      'isEditRoi',
    ]),
    ...mapState('recgLpr', [
      'currDefaultSetting', 
      'currentSubscribers',
      'note',
    ]),
    ...mapGetters('recgLpr', [
      'userId',
      'userIndex', 
      'deviceModelId',
      'currentSetting',
      'isAiCam',
      'isLprCam',
      'isSettingReady',
      'isSettingSrcModified',
      'isSettingModified',
      'isNoteModified',
      'isSubscribersModified',
      'isNotifyFilterModeModified',
    ]),
    ...mapGetters('aibox', [
      'aiBoxTask',
    ]),
    isDataReady() {
      return this.aiRunOn === 'device' && this.isSettingReady || 
        this.aiRunOn === 'aibox' && this.aiBoxTask
    },
    isModified() {
      if (this.aiRunOn === 'device') {
        return this.isSettingSrcModified || this.isSettingModified || 
          this.isNoteModified || this.isSubscribersModified ||
          this.isNotifyFilterModeModified
      } else 
        return this.$store.getters['aibox/isModified']
    },
    useSubscribers: {
      get() {
        if (this.aiRunOn === 'device')
          return this.currentSubscribers
        else if (this.aiRunOn === 'aibox')
          return this.aiBoxTask.subscribers
        return []
      },
      set(val) {
        if (this.aiRunOn === 'device')
          this.updateCurrentSubscribers(val)
        else if (this.aiRunOn === 'aibox')
          this.$store.commit('aibox/updateSubscribers', val)
      }
    },
    useNote: {
      get() {
        if (this.aiRunOn === 'device')
          return this.note
        else if (this.aiRunOn === 'aibox')
          return this.aiBoxTask.notice
        return ''
      },
      set(val) {
        if (this.aiRunOn === 'device')
          this.updateNote(val)
        else if (this.aiRunOn === 'aibox')
          this.$store.commit('aibox/updateNotice', val)
      }
    },
    editorUserId() {
      if (this.aiRunOn === 'device')
        return this.currentSetting.editorUserId
      else if (this.aiRunOn === 'aibox')
        return this.aiBoxTask.editorUserId
      return ''
    },
  },
  mounted() {
    if (this.aiRunOn === 'device') this.onInit()  
    if (this.aiRunOn === 'aibox') this.onAiboxLprInit()
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapMutations('aibox', [ 
      'updateIsEdit',
      'updateParamAiboxPortal', 
      'updateShowAiboxPortal',
    ]),
    ...mapMutations('recgLpr', [
      'resetState',
      'updateIsEditRoi',
      'updateCurrentSubscribers',
      'updateNote',
    ]),
    ...mapActions('recgLpr', [
      'getTagList',
      'getDefaultSetting',
      'getUserSetting',
      'getUserInfo',
      'getUserSubscribers',
      'initSetting',
      'editUser',
      'editUserSetting',
      'handleUserSubscribers',
      'onUpdateCurrDefaultSetting',
    ]),
    async onInit() {
      try {
        this.isLoading = true
        this.getTagList()
        this.getUserSubscribers()
        await this.getUserInfo() // get defaultSetting, frNote / lprNote
        await this.getDefaultSetting()
        await this.getUserSetting()
        
        // -------
        // 若為AiCam，永遠只能是設備客製
        if (this.isAiCam) {
          if (this.currDefaultSetting === 1) {
            this.onUpdateCurrDefaultSetting(0)
            await this.editUser() // 參數來源, frNote
          }
        }
        // -------

        this.initSetting()
      } catch (err) {
        console.log('err = ', err)
      } finally {
        this.isLoading = false
      }
    },
    onAiboxLprInit() {
      this.$store.dispatch('aibox/getTagList')
    },
    cancelTaskEdit() {
      if (!this.isModified) {
        this.updateIsEdit(false)
        return
      }
      // 出現cancelEdit跳窗詢問，確認後的動作為切換模式到檢視模式
      const action = this.aiRunOn === 'device' ? 'cancelDeviceLprEdit' : 'cancelAiboxLprEdit'
      this.updateParamAiboxPortal({ info: 'cancelEdit', action: action })
      this.updateShowAiboxPortal(true)
    },
    async handleTaskEdit() {
      if (this.aiRunOn === 'device') {
        await this.editUserSetting()
        await this.handleUserSubscribers()
        await this.editUser() // 參數來源, frNote
      } else {
        // aibox
        await this.$store.dispatch('aibox/addOrUpdateAiBoxTask')
        await this.$store.dispatch('aibox/editSubscribers')
      }
      this.updateIsEdit(false)
    },
    editRoi() {
      this.updateIsEditRoi(true)
    },
    lockRoi() {
      this.updateIsEditRoi(false)
    },
    onCancel() {},
    onChangeSubscribers(data) {
      if (this.aiRunOn === 'device') {
        const modelMap = {
          lpr: 1,
          or: 2,
          fr: 3,
        }
        const newUsers = data.map(node => ({
          userId: this.userIndex,
          subscriberId: node.index,
          model: modelMap[this.aiMode],
        }))
        this.updateCurrentSubscribers(newUsers)
      } else {
        const newUsers = data.map(node => {
          const groupName = this.groupList.find(group => group.id === node.groupId).name
          return {
            groupId: node.groupId,
            groupName: groupName || '',
            userAccount: node.id,
            userId: node.index,
            userName: node.name,
          }
        })
        this.$store.commit('aibox/updateSubscribers', newUsers)
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.recognition-setting {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 68% 32%;
  grid-template-rows: 50% 13% 20% 12%;
  grid-template-areas: 
    "canvas setting"
    "tags setting"
    "receiver remark"
    "receiver editor";
  grid-gap: 12px;
  // padding: 16px 36px 12px 20px;
  background: #282942;
  position: relative;
}

.container-canvas {
  grid-area: canvas;
  background: #39425D;
  border-radius: 8px;
}

.container-setting {
  grid-area: setting;
}

.container-tags {
  grid-area: tags;
  background: #39425D;
  border-radius: 8px;
}

.container-receiver {
  grid-area: receiver;
}

.container-note {
  grid-area: remark;
  .wrap-edit-roi {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 12px;
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  .edit-roi {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    padding: 4px 12px;
    background: #4A5C78;
    border-radius: 8px;
    cursor: pointer;
    
    img {
      margin-right: 8px;
    }
  }
  .reset-roi {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: #4A5C7880;
    cursor: pointer;
    &:hover {
      background: #4A5C78;
    }
  }
}

.container-editor {
  grid-area: editor;
  &.end {
    display: flex;
    align-items: flex-end;
  }
}

.actions {
  width: 100%;
  height: 64px;
  border-radius: 8px;
  display: flex;
  font-size: 16px;
  padding: 12px 12px 0 12px;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  .btn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #282942;
    border-radius: 9px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    &:hover {
      background: #FFB703;
    }
    &.cancel {
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 9px;
      text-align: center;
      margin-right: 24px;
      padding-top: 8px;
      padding-bottom: 8px;
      cursor: pointer;
      &:hover {
        background: #ffffff33;
      }
    }
    &.confirm {
      background: #FFC600;
      color: #282942;
      border-radius: 9px;
      text-align: center;
      padding-top: 8px;
      padding-bottom: 8px;
      cursor: pointer;
      &:hover {
        background: #FFB703;
      }
    }
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
</style>